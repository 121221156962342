import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HomeP from '../views/HomeProf.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/loginProf',
    name: 'HomeP',
    component: HomeP
  },
  {
    path: '/centre',
    name: 'LoginCentre',
    component: () =>
        import( /* webpackChunkName: "Clients" */ '../views/Centre.vue'),
  },
  {
    path: '/recu',
    name: 'Recu',
    component: () =>
        import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/recu/Recu.vue'),
  },
  {
    path: '/fiche',
    name: 'Fiche',
    component: () =>
        import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Fiche/Fiche.vue'),
  },
  {
    path: '/list',
    name: 'List',
    component: () =>
        import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Liste/Liste.vue'),
  },
  {
    path: '/listi',
    name: 'ListI',
    component: () =>
        import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/ListeI/Liste.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin.vue'),
    children: [
      {
          path: 'clients',
          name: 'Clients',
          component: () =>
              import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
      },
      {
        path: 'clients/:id',
        name: 'Clients2',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
      },
      {
        path: 'Inscription',
        name: 'Inscription',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/inscription/Inscription.vue'),
    },
    {
        path: 'Factures',
        name: 'Factures',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/facture/Facture.vue'),
    },
    {
        path: 'ResteAPayer',
        name: 'ResteAPayer',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'Prospects',
        name: 'Prospects',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'Seances',
        name: 'Seances',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/seances/Seances.vue'),
    },
    {
        path: 'PGroupes',
        name: 'PGroupes',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/groupe2P/Groupe.vue'),
    },
    {
        path: 'StockDoc',
        name: 'StockDoc',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'MouvStockDoc',
        name: 'MouvStockDoc',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'Caisse',
        name: 'Caisse', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/caisse/Caisse.vue'),
    },
    {
        path: 'ProfsAvanceH',
        name: 'ProfsAvanceH', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/avances/avanceprof.vue'),
    },
    {
        path: 'UsersAvanceH',
        name: 'UsersAvanceH', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/avances/avanceuser.vue'),
    },
    {
        path: 'ProfsAvance',
        name: 'ProfsAvance', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/profAvance/Prof.vue'),
    },
    {
        path: 'Logs',
        name: 'Logs', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/logs/logs.vue'),
    },
    {
        path: 'CaisseG',
        name: 'CaisseG', 
        component: () =>
            import( /* webpackChunkName: "/caisse/Caisse" */ '../views/AdminComponents/caisseGlobal/Caisse.vue'),
    },
    {
        path: 'Depenses',
        name: 'Depenses',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'MouvAssoc',
        name: 'MouvAssoc',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'Assoc',
        name: 'Assoc',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'RecapGroupe',
        name: 'RecapGroupe',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'RecapInsc',
        name: 'RecapInsc',
        component: () =>
            import( /* webpackChunkName: "Clients" */ '../views/AdminComponents/Clients/Clients.vue'),
    },
    {
        path: 'niveau',
        name: 'Niveau',
        component: () =>
            import( /* webpackChunkName: "Niveau" */ '../views/AdminComponents/niveau/Niveau.vue'),
    },
    {
        path: 'niveaub',
        name: 'NiveauB',
        component: () =>
            import( /* webpackChunkName: "Niveau" */ '../views/AdminComponents/niveauB/Niveau.vue'),
    },
    {
        path: 'categorie',
        name: 'Categorie',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/categorie/Categorie.vue'),
    },
    {
        path: 'banque',
        name: 'Banque',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/banque/Banque.vue'),
    },
    {
        path: 'cheque',
        name: 'Cheque',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/cheque/Cheque.vue'),
    },
    {
        path: 'horaire',
        name: 'Horaire',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/horaire/Horaire.vue'),
    },
      {
          path: 'centre',
          name: 'Centre',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/centre/Centre.vue'),
      },
      {
          path: 'user',
          name: 'User',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/user/User.vue'),
      },
      {
          path: 'matiere',
          name: 'Matiere',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/matiere/Matiere.vue'),
      },
      {
          path: 'rubrique',
          name: 'Rubrique',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/rubrique/Rubrique.vue'),
      },
      {
          path: 'salle',
          name: 'Salle',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/salle/Salle.vue'),
      },
      {
          path: 'sousRubrique',
          name: 'SousRubrique',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/sousRubrique/SousRubrique.vue'),
      },
      {
          path: 'associes',
          name: 'Associes',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/associes/Associes.vue'),
      },
      {
          path: 'bareme',
          name: 'Bareme',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/bareme/Bareme.vue'),
      },
      {
          path: 'cadence',
          name: 'Cadence',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/cadence/Cadence.vue'),
      },
      {
          path: 'annees',
          name: 'Annees',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/annees/Annee.vue'),
      },
      {
          path: 'convention',
          name: 'Convention',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/convention/Convention.vue'),
      },
      {
          path: 'documentation',
          name: 'Documentation',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/documentation/Documentation.vue'),
      },
      {
          path: 'prof',
          name: 'Prof',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/prof/Prof.vue'),
      },
      {
          path: 'groupe',
          name: 'Groupe',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/groupe/Groupe.vue'),
      },
      {
        path: 'groupes',
        name: 'Groupes',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/groupe2/Groupe.vue'),
    },
      {
          path: 'facture',
          name: 'Facture',
          component: () =>
              import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/facture/Facture.vue'),
      },
      {
        path: 'formule',
        name: 'Formule',
        component: () =>
            import( /* webpackChunkName: "Categorie" */ '../views/AdminComponents/formule/Formule.vue'),
    },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
